import React from "react";
import { Layout } from "antd";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <Layout.Footer>
        <div className="footer-info">
          <div className="footer-address">
            <h3>Address</h3>
            <p>
              1720 Villa St
              <br />
              Mountain View, CA 94041
              <br />
              United States
            </p>
          </div>
          <div className="footer-contact">
            <h3>Contact</h3>
            <p>hello@reghero.ai</p>
            <h3>Careers</h3>
            <p>careers@reghero.ai</p>
          </div>
        </div>
        <div className="footer-copyright">©2023, RegHero.ai LLC</div>
      </Layout.Footer>
    </div>
  );
};

export default Footer;
