import React from "react";
import ChatImg from "../images/chat.png";
import DraftImg from "../images/draft.png";
import ValidateImg from "../images/dry-run.png";

function Features() {
  return (
    <div className="features-section">
      <div className="container">
        <div className="feature">
          <div className="feature-image-container">
            <img className="feature-image" src={ChatImg} alt="process" />
          </div>
          <div className="feature-text">
            <h2>
              Instant Insights,
              <br /> Grounded in FDA Rules
            </h2>
            <div className="feature-text-small">
              <div className="feature-text-details">
                Chat with our AI regulatory expert 24/7 for immediate clarity.
              </div>
              <div className="feature-text-details">
                Every answer is grounded in FDA databases, ensuring
                verifiability.
              </div>
            </div>
          </div>
        </div>

        <div className="feature">
          <div className="feature-text">
            <h2>
              Start Ahead,
              <br /> Not From Scratch
            </h2>
            <div className="feature-text-details">
              RegHero's AI crafts draft documents for you, amplifying your
              productivity and efficiency.
            </div>
            <div className="feature-text-details">
              Rely on drafts with credible sources and citations, derived from
              your input and authoritative guidelines.
            </div>
            <div className="feature-text-details">
              We cater to 510k predicate, clinical protocols, and beyond.
            </div>
          </div>
          <div className="feature-image-container">
            <img className="feature-image" src={DraftImg} alt="process" />
          </div>
        </div>
        {/* 
        <div className="feature">
          <div className="feature-image-container">
            <img className="feature-image" src={ValidateImg} alt="process" />
          </div>
          <div className="feature-text">
            <h2>Submit Documents with Confidence</h2>
            <div className="feature-text-small">
              <div className="feature-text-details">
                RegHero's AI performs a meticulous review, aligning your
                documents with essential checklists prior to submission.
              </div>
              <div className="feature-text-details">
                Experience peace of mind, knowing your submissions meet the
                standards.
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Features;
