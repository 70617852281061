import React from "react";
import "./About.scss";
import Footer from "./Footer";

import TeamImg from "./images/team.png";
import BoardImg from "./images/board.png";

const BackgroundCard = ({ title, description, img }) => {
  return (
    <div className="background-card">
      <h2>{title}</h2>
      <div className="description">{description}</div>
      <img src={img} alt="background" />
    </div>
  );
};

function About() {
  return (
    <div>
      <div className="about">
        <div className="mission">
          <h4>Our Mission</h4>
          <h1>
            Accelerate medical innovations to patient care by simplifying the
            regulatory journey.
          </h1>
        </div>
        <div className="headquarter">
          <h2>Sillicon Valley</h2>
          <h3>HEADQUARTER</h3>
        </div>
        <div className="background">
          <BackgroundCard
            title="Team"
            description="Our core team is deeply rooted in AI and Natural Language Processing (NLP). With experience ranging from leading tech companies to dynamic AI startups, we've developed enterprise solutions that truly make a difference."
            img={TeamImg}
          />
          <BackgroundCard
            title="Advisory Board"
            description="Guided by seasoned Regulatory Affairs professionals, our Advisory Board is our strategic backbone. Many have led regulatory teams themselves, ensuring that our approaches stay grounded and effective in the industry."
            img={BoardImg}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
