import React from "react";
import HeroSection from "./HeroSection";
// import Brands from "./Brands";
import Features from "./Features";
import Testimonials from "./Testimonials";
import CallToAction from "./CallToAction";
import Footer from "../Footer";
import "./LandingPage.scss";

function LandingPage() {
  return (
    <div className="landing">
      <HeroSection />
      <Features />
      <Testimonials />
      {/* <Brands /> */}
      <CallToAction />
      <Footer />
    </div>
  );
}

export default LandingPage;
