import React from "react";
import "./Contact.scss";
import Footer from "./Footer";

function Contact() {
  return (
    <div>
      <div className="contact">
        <h1>We would love to hear from you</h1>
        <h2>hello@reghero.ai</h2>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
