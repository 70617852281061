import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => (
  <div className="cta-section">
    <div className="cta-content">
      <div className="cta-slogan">
        <h1>Experience the Future of Regulatory Affairs with RegHero</h1>
        <h4>Launching First in the US, with Global Horizons Ahead</h4>
      </div>
      <div className="cta-button-container">
        <Link to="/contact">
          <button className="cta-button">Contact Us</button>
        </Link>
      </div>
    </div>
  </div>
);

export default CallToAction;
