import React from "react";

function Testimonials() {
  return (
    <div className="testimonials">
      <h2>Teams move faster with RegHero.</h2>

      <div className="testimonial-slider">
        {/* <button className="prev">‹</button> */}
        <div className="testimonial-card">
          {/* <img
            src="logo-grammarly.png"
            alt="Grammarly logo"
            className="brand-logo"
          /> */}
          <h3>
            "In the fast-paced world of medical device startups, the regulatory
            journey is often a challenging maze. I've always wished for a tool
            that could simplify and expedite the process. Hearing about
            RegHero's vision and mission gives me hope. This could be the
            solution we've all been waiting for.”
          </h3>
          <div className="author">
            {/* <img src="heidi-williams.jpg" alt="Heidi Williams" /> */}
            {/* <h4>John Doe</h4> */}
            {/* <p>Regulatory Affairs Expert, IQVIA</p> */}
          </div>
        </div>
        {/* <button className="next">›</button> */}
      </div>
    </div>
  );
}

export default Testimonials;
