import React, { useState } from "react";
import "./NavBar.scss";

import { Link } from "react-router-dom";
import Logo from "./images/logo.png";

const isMobile = window.innerWidth <= 768;

function Navbar() {
  if (isMobile) {
    return <MobileNavbar />;
  }

  return (
    <nav className="navbar">
      <div className="logo-container">
        <Link to="/">
          <img src={Logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="menu">
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </nav>
  );
}

// ------ mobile ------
function MobileNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="mobile-nav">
      <div className="mobile-navbar">
        <div className="mobile-logo-container">
          <Link to="/">
            <img src={Logo} alt="Logo" className="mobile-logo" />
          </Link>
        </div>
        <div className="mobile-menu-icon" onClick={toggleMenu}>
          ☰
        </div>
      </div>
      {isOpen && (
        <div className="mobile-menu">
          <Link to="/about" onClick={() => setIsOpen(false)}>
            About
          </Link>
          <Link to="/contact" onClick={() => setIsOpen(false)}>
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
