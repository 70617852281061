import React from "react";

function HeroSection() {
  return (
    <div className="hero-section">
      <div className="container">
        <div className="hero-content">
          <div className="hero-text">
            <div className="hero-title">
              Medical Device Compliance Accelerated with AI
            </div>
            <h3 className="hero-details">
              Navigate your medical device regulatory journey effortlessly with
              RegHero.
            </h3>
            <h2>Launching Soon</h2>
            {/* <TryOutButton /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const TryOutButton = () => {
  const handleButtonClick = () => {
    // Open the URL in a new tab
    window.open("https://reghero.netlify.app/", "_blank");
  };

  return (
    <button className="cta-button" onClick={handleButtonClick}>
      Try the Beta Now
    </button>
  );
};

export default HeroSection;
